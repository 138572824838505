import { Helmet } from "react-helmet-async";
import { pageMetatags } from "../../../utils/MetatagsGenerator";
import Header from "../../page-elements/Header";
import { useAuth } from "../../../firebase/AuthContext";
import { useSearchParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import cookies from "../../../utils/CookiesManager";
import { RecordReferralImpression } from "../../../backend/Referrals";
import date from "date-and-time";

const PressPage = () => {
  const { currentUser } = useAuth();
  const [queryParams] = useSearchParams();

  const didReferralImpression = useRef(false);

  useEffect(() => {
    const referralID = queryParams.get("referralID");
    if (!currentUser && referralID && !didReferralImpression.current) {
      cookies.set("coursableReferralID", referralID, { expires: date.addDays(new Date(), 7) });
      RecordReferralImpression(referralID);
      didReferralImpression.current = true;
    }
  }, []);

  return (
    <div className="w-full h-screen flex flex-col bg-background">
      <Helmet>
        {pageMetatags({
          title: "Press",
          description:
            "Coursable press kit. Find all relevant media, logos, explanations, and more. Coursable supports students by analyzing their documents, answering questions, generating study materials, and more, all with a personalized feel.",
          canonical: "/press",
        })}
      </Helmet>
      <Header />
      <iframe key="press" className="w-full h-full border-brand-600" title="Coursable Press Kit" src="https://coursable.pressdeck.io" />
    </div>
  );
};

export default PressPage;
